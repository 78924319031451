.header__social-link {
  cursor: pointer;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: 0.5s;

  .header__link-block {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: inherit;
    border-radius: 10px;
    padding: 5px 10px;
    transition: 0.5s;
    font-size: 1.6vw;

    @media screen and (max-width: 700px) {
      font-size: 2.5vh;
    }
  }

  .header__link-block:hover {
    transition: 0.5s;

    svg {
      fill: rgb(23, 195, 23);
      transition: 0.5s;
    }
  }
}
