body {
  margin: 0;
  font-family: "Montserrat", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: white;
}

/* Обычный */
@font-face {
  font-family: "MontserratThin";
  src: url(./fonts/Montserrat-Thin.ttf);
}

/* Жирный */
@font-face {
  font-family: "MontserratLight";
  src: url(./fonts/Montserrat-Light.ttf);
}

@font-face {
  font-family: "MontserratBlack";
  src: url(./fonts/Montserrat-Black.ttf);
}

@font-face {
  font-family: "MontserratBold";
  src: url(./fonts/Montserrat-Bold.ttf);
}

@font-face {
  font-family: "MontserratMedium";
  src: url(./fonts/Montserrat-Medium.ttf);
}

@font-face {
  font-family: "MontserratRegular";
  src: url(./fonts//Montserrat-Regular.ttf);
}

/* @font-face {
  font-family: "PoppinsThin";
  src: url(./fonts/Poppins-Thin.ttf);
} */

@font-face {
  font-family: "MontserratExtraLight";
  src: url(./fonts/Montserrat-ExtraLight.ttf);
}

/* @font-face {
  font-family: "PoppinsLight";
  src: url(./fonts/Poppins-Light.ttf);
} */

/* @font-face {
  font-family: "PoppinsRegular";
  src: url(./fonts/Poppins-Regular.ttf);
} */

@font-face {
  font-family: "MontserratItalic";
  src: url(./fonts/Montserrat-Italic.ttf);
}

@font-face {
  font-family: "MontserratSemiBold";
  src: url(./fonts/Montserrat-SemiBold.ttf);
}

@font-face {
  font-family: "MontserratExtraBold";
  src: url(./fonts/Montserrat-ExtraBold.ttf);
}

@font-face {
  font-family: "GeologicaExtraLight";
  src: url(./fonts/Geologica_Auto-ExtraLight.ttf);
}

@font-face {
  font-family: "GeologicaMedium";
  src: url(./fonts/Geologica_Auto-Medium.ttf);
}

@font-face {
  font-family: "GeologicaSemiBold";
  src: url(./fonts/Geologica-SemiBold.ttf);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

@keyframes textclip {
  to {
    background-position: 200% center;
  }
}
