.header {
  position: sticky;
  width: 100%;
  top: 0;
  height: 82px;
  display: grid;
  grid-template-columns: 1fr 2fr 0.8fr;
  align-items: center;
  box-sizing: border-box;
  background-color: rgba(255, 255, 255, 0.6);
  backdrop-filter: blur(10px);
  padding: 1rem 40px;
  z-index: 1000;

  @media screen and (max-width: 700px) {
    height: 8vh;
    display: grid;
    grid-template-columns: 1fr 50px;
    padding: 0 20px;
    box-sizing: border-box;
    justify-content: space-between;
  }

  .header__image-container {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start;

    a {
      height: 100%;
      display: flex;
      align-items: flex-end;

      @media screen and (max-width: 700px) {
        display: flex;
        align-items: center;
        width: 70%;
      }
    }

    @media screen and (max-width: 700px) {
      justify-content: flex-start;
      img {
        width: 186px;
        height: 28px;
      }
    }
  }

  .header__navigate-block {
    display: flex;
    gap: 1.5vw;
    font-family: "MontserratSemiBold", sans-serif;
    color: black;
    cursor: pointer;
    height: 100%;
    align-items: flex-end;
    box-sizing: border-box;

    .header__navigate-block-item {
      position: relative;
    }

    .header__navigate-item {
      transition: color 0.3s ease;
      font-size: 1.2vw;
    }

    .header__navigate-item::before {
      content: "";
      position: absolute;
      top: -20px;
      left: 0;
      width: 100%;
      height: 8px;
      background-color: #a79df3;
      border-radius: 11px;
      transform: scaleX(0);
      transform-origin: left;
      transition: transform 0.3s ease;
    }

    .header__navigate-item:hover::before,
    .header__navigate-item:focus::before,
    .header__navigate-block-item.active-item .header__navigate-item::before {
      transform: scaleX(1);
    }

    .header__number-phone {
      a {
        text-decoration: none;
        color: black;
        font-size: 1.2vw;
      }
    }
  }

  .header__info-block {
    display: flex;
    gap: 20px;
    height: 100%;
    align-items: center;
    justify-content: center;

    .header__social-link {
      height: 45px;
      width: 45px;
      border-radius: 50%;
      background-color: #45c179;
      transition: 0.3s;

      .header__social-link__icon {
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }

    .header__social-link:hover {
      background-color: black;
      transition: 0.3s;
    }

    .header__button {
      font-family: "GeologicaSemiBold";
      font-size: 15px;
      letter-spacing: 1px;
      line-height: 20px;
      border-radius: 12px !important;
      text-transform: uppercase;
      height: 6vh;
      width: 15vw;
      box-shadow: none;
    }
  }
}

.header.active {
  top: 0;

  .header_menu_burger_button {
    img {
      aspect-ratio: 1/1;
    }
  }
}

.header.hidden {
  top: -100px;
  transition: top 0.3s;
}
