.modal__wrapper {
  display: grid;
  grid-template-rows: 52px 1fr;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  // min-height: 50vh;
  // min-width: 25vw;
  background-color: white;
  border-radius: 20px;

  @media screen and (max-width: 700px) {
    min-width: 100%;
    max-width: 100%;
    min-height: auto;
  }

  .modal__header {
    display: flex;
    align-items: center;
    justify-content: space-between;

    p {
      font-size: 2.5vh;
      font-weight: 600;
      color: #29a354;
      font-family: "RobotoBlack";
      font-style: normal;
      line-height: normal;
      margin: 0;
    }

    .modal__empty {
      height: 30px;
      width: 30px;
      margin-left: 20px;
    }

    .modal__btn-close {
      height: 2vh;
      width: 2vw;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-right: 20px;

      svg {
        font-size: 1.5vh;
      }

      svg:hover {
        background-color: rgb(227, 224, 224);
        padding: 7px;
        border-radius: 50px;
        cursor: pointer;
      }
    }
  }
}
