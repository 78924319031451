.benefit__container {
  width: 100%;
  padding: 0 40px;
  box-sizing: border-box;

  @media screen and (max-width: 700px) {
    padding: 0;
  }

  .benefit__all-content {
    box-sizing: border-box;
    width: 100%;
    display: flex;
    flex-direction: column;
    background-color: #faf5f1;
    border-radius: 40px;
    position: relative;
    overflow: hidden;
    padding-bottom: 100px;
    margin-bottom: 100px;

    @media screen and (max-width: 700px) {
      padding-bottom: 50px;
      margin-bottom: 60px;
    }
  }

  .benefit__all-content::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: url("./assets/wrapperBenefit.png"); /* Замените на ваш URL изображения */
    background-size: cover;
    background-position: center;
    z-index: 1;

    @media screen and (max-width: 700px) {
      background-position: 32% 75%;
    }
  }

  .benefit__main-text {
    text-align: left;
    font-family: "MontserratBold";
    font-size: 48px;
    line-height: 79px;
    margin: 0;
    padding-left: 7%;
    z-index: 2;

    @media screen and (max-width: 700px) {
      font-size: 32px;
      padding: 0;
    }

    h2 {
      width: 50%;

      @media screen and (max-width: 700px) {
        font-size: 32px;
        padding-left: 15px;
      }
    }

    @media screen and (max-width: 700px) {
      width: 100%;
      font-size: 3vh;
      line-height: 150%;
    }
  }

  .benefit__content {
    display: grid;
    align-items: center;
    grid-template-columns: 1fr 1fr;
    gap: 20%;

    @media screen and (max-width: 700px) {
      display: flex;
      flex-direction: column;
      position: relative;
    }

    .benefit__card {
      width: 100%;
      height: 100%;
      align-items: center;
      justify-content: flex-start;
      display: flex;
      flex-direction: column;
      gap: 4.5vh;

      @media screen and (max-width: 700px) {
        flex-direction: column;
        z-index: 2;
        padding: 0 15px;
        box-sizing: border-box;
        gap: 3vh;
      }

      .benefit__content-card {
        text-align: initial;
        width: 70%;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        gap: 40px;
        position: relative;
        z-index: 2;

        @media screen and (max-width: 700px) {
          width: 100%;
        }

        .benefit__text {
          display: flex;
          flex-direction: column;
          gap: 1.5vw;
          height: 100%;

          @media screen and (max-width: 700px) {
            text-align: left;
            width: 100%;
            justify-content: center;
          }

          .benefit__block-with-icon {
            display: flex;
            gap: 20px;
            align-items: center;

            img {
              height: 32px;
              width: 32px;
            }
          }

          p {
            color: black;
            font-family: "MontserratBold";
            font-size: 24px;
            font-style: normal;
            font-weight: 600;
            line-height: 29px;
            margin: 0;

            @media screen and (max-width: 700px) {
              font-size: 18px;
              line-height: 20px;
            }
          }

          span {
            font-family: "MontserratRegular";
            font-size: 1.3vw;
            font-style: normal;
            font-weight: 400;
            line-height: 27px;

            @media screen and (max-width: 700px) {
              font-size: 16px;
              line-height: 24px;
            }
          }
        }
      }
    }

    .benefit__image-chess {
      @media screen and (max-width: 700px) {
        position: absolute;
        top: 7%;
      }
    }
  }
}
