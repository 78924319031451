.navigation-block__container {
  display: flex;
  justify-content: space-between;
  height: 100%;
  flex-direction: column;
  padding-bottom: 20px;
  box-sizing: border-box;

  .navigation-block__content {
    display: flex;
    flex-direction: column;
    gap: 50px;

    .navigation-block__links {
      display: flex;
      gap: 30px;
    }

    .navigation-block_nav {
      display: flex;
      flex-direction: column;
      gap: 30px;

      .navigation-block__item {
        .navigation-block__nav-el {
          font-family: "MontserratBold";
          font-size: 24px;
          line-height: 30px;
        }
      }

      .navigation-block__number-phone {
        a {
          color: black;
          text-decoration: none;
          font-family: "MontserratBold";
          font-size: 24px;
          line-height: 30px;
        }
      }
    }
  }

  .navigation-block__button {
    .sign-up__button {
      width: 100%;
      height: 50px;
      border-radius: 12px;
      text-transform: uppercase;
      font-size: 16px;
      letter-spacing: 1px;
      line-height: 20px;
      font-family: "GeologicaSemiBold";
      box-shadow: none;
    }
  }
}
