.card-recommend__content {
  width: 45%;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  padding: 40px 30px;
  gap: 10px;

  @media screen and (max-width: 700px) {
    width: 100%;
    padding: 30px 0;
  }

  .card-recommend__main {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    text-align: justify;

    @media screen and (max-width: 700px) {
      flex-direction: row;
      text-align: left;
      gap: 20px;
      align-items: flex-end;
    }

    .card-recommend__image {
      img {
        aspect-ratio: 1/1;
      }
    }

    .card-recommend__title {
      padding-bottom: 20px;
    }

    .card-recommend__title-text {
      margin: 0;
      font-family: "MontserratBold";
      font-size: 20px;
      line-height: 24px;

      @media screen and (max-width: 700px) {
        width: 70%;
        display: flex;
      }
    }
  }

  .card-recommend__description {
    display: grid;
    // grid-template-columns: 1fr 2fr;
    grid-template-columns: 45% 55%;
    // align-items: center;
    align-items: baseline;

    text-align: left;
    gap: 50px;
    font-family: "MontserratLight";
    font-size: 18px;
    line-height: 27px;

    @media screen and (max-width: 700px) {
      grid-template-columns: 1fr;
    }

    .card-recommend__title-text {
      margin: 0;
      font-family: "MontserratBold";
      font-size: 20px;
      line-height: 24px;
    }
  }
}

.card-recommend__content.scroll {
  @media screen and (max-width: 700px) {
    min-width: 330px !important;
    padding: 0;
  }
}

@media screen and (max-width: 700px) {
  .card-recommend__content:not(:last-child) {
    border-bottom: 2px solid #dce204;
  }

  .card-recommend__content.scroll {
    border-bottom: none;
    border-right: 2px solid #dce204;
    padding-right: 40px;
  }
}
