.failure-page__wrapper {
  position: relative;
  box-sizing: border-box;
  height: 100vh;
  width: 100%;
  background: linear-gradient(
    90deg,
    rgba(2, 0, 36, 1) 0%,
    rgba(175, 16, 50, 1) 56%,
    rgba(221, 255, 0, 1) 100%
  );

  .failure-page__content {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    .failure-page__card {
      width: 500px;
      height: 600px;
      position: relative;
      background: rgba(255, 255, 255, 0.192);
      backdrop-filter: blur(10px);
      border-radius: 15px;
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 50px;
      padding: 50px;
      box-sizing: border-box;
      justify-content: center;
    }

    .failure-page__text {
      display: flex;
      flex-direction: column;
      color: aliceblue;

      .failure-page__title {
        font-size: 20px;
        font-weight: 600;
      }
    }

    button {
      background: linear-gradient(90deg, rgba(175, 16, 50, 1) 56%);
    }

    .failure-page__icon {
      height: 140px;
      width: 140px;

      img {
        height: 100%;
      }
    }

    .failure-page__card:before {
      position: absolute;
      content: "";
      background: inherit;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      // box-shadow: inset 0 0 0 3000px rgba(150, 150, 150, 0.192);
      filter: blur(10px);
      border-radius: 15px;
    }
  }
}
