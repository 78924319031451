.input__container {
  .MuiFilledInput-root {
    background-color: initial;
  }

  .MuiFilledInput-root::before {
    border: none;
  }
  .MuiFilledInput-root:hover {
    background-color: initial;
    cursor: pointer;
  }

  .MuiFilledInput-root:hover:not(.Mui-disabled, .Mui-error):before {
    border-bottom: none;
  }

  .MuiInputLabel-root.Mui-focused {
    color: black;
    font-family: "GeologicaExtraLight";
    font-size: 14px;
    line-height: 16px;
    left: 3px;

    @media screen and (max-width: 700px) {
      font-size: 14px;
    }
  }

  .MuiInputLabel-root {
    font-family: "GeologicaExtraLight";
    font-size: 16px;
    line-height: 16px;
    color: #000000;
    left: 3px;
  }

  .MuiFilledInput-root:after {
    border-bottom: none;
  }
  .MuiFilledInput-root.Mui-focused {
    background-color: initial;
  }

  #input-basic {
    font-family: "GeologicaMedium";
    font-size: 16px;
    line-height: 16px;
    background-color: #faf5f1 !important;
    border-radius: 12px;
    padding: 16px 16px 8px;
    height: 26px;
  }

  .input__textfield.error {
    input {
      border: 1px solid #da0000;
    }
  }
}
