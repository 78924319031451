.finally-content__container {
  width: 100%;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  margin-top: 30px;
  gap: 40px;

  .ant-space-item {
    width: 100%;
  }

  .finally-content__image {
    width: 100%;
    box-sizing: border-box;

    img {
      width: 100%;
      border-radius: 40px;
      height: 300px;
      object-fit: cover;

      @media screen and (max-width: 700px) {
        height: 180px;
      }
    }
  }

  .finally-content__title-container {
    width: 100%;
    display: flex;
    gap: 16px;

    .finally-content__title {
      font-family: "MontserratBold";
      font-size: 32px;
      margin: 0;
      line-height: 38px;
    }

    .finally-content__text {
      font-family: "MontserratLight";
      font-size: 16px;
      line-height: 24px;
    }
  }

  .finally-button {
    display: flex;

    .button {
      margin-top: 12px;
      width: 100%;
      height: 50px;
      font-size: 16px;
      border-radius: 12px !important;
      text-transform: uppercase;
      letter-spacing: 2px;
      font-family: "MontserratBold";
      padding: 10px;
    }
  }
}
