.footer__container {
  display: flex;
  background-color: black;
  color: white;
  align-items: center;
  justify-content: center;
  padding: 9vh 80px;
  border-radius: 60px 60px 0px 0px;

  @media screen and (max-width: 700px) {
    padding: 9vh 40px 13vh;
  }

  .footer__links {
    display: flex;
    gap: 40px;
    align-items: center;

    .footer__logo {
      width: 100%;
      img {
        width: 260px;
        height: 40px;

        @media screen and (max-width: 700px) {
          height: 100%;
          width: 100%;
        }
      }
    }

    @media screen and (max-width: 700px) {
      flex-direction: column;
      align-items: flex-start;
    }
  }

  .footer__docs {
    display: flex;
    flex-direction: column;
    gap: 2vw;
    align-items: flex-end;

    @media screen and (max-width: 700px) {
      align-items: flex-start;
      gap: 5vw;
    }

    a {
      text-decoration: none;
      color: white;
      font-size: 1.3vw;
      font-family: "MontserratLight";
      transition: 0.3s;

      @media screen and (max-width: 700px) {
        font-size: 1.9vh;
      }
    }

    a:hover {
      transition: 0.3s;
      color: #464bff;
    }
  }

  .footer__block {
    display: flex;
    gap: 4vh;
    justify-content: space-between;
    height: 100%;
    width: 100%;

    @media screen and (max-width: 700px) {
      flex-direction: column;
    }

    img {
      height: 100%;
    }
  }

  .footer__icons {
    display: flex;
    gap: 40px;
    height: 40px;
  }
}
