.recomended-items__container {
  display: flex;
  justify-content: center;
  align-items: center;
  background: white;
  height: 100%;
  position: relative;
  gap: 50px;
  box-sizing: border-box;
  flex-wrap: wrap;

  .recomended-items__content {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    column-gap: 70px;
    padding: 40px;

    @media screen and (max-width: 700px) {
      // padding: 40px 15px;
      padding: 20px;
      // flex-direction: column;
      box-sizing: border-box;
    }

    .recomended-items__bordered {
      width: 100%;
      border: 2px solid #dce204;
    }
  }

  .recomended-items__content.scroll {
    overflow-x: scroll;
    flex-wrap: initial;
    justify-content: flex-start;
  }
}

.recomended-items__container.scroll {
  width: calc(100vw - 30px);
}
