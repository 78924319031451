.about-us-page__container {
  width: 100%;
  background-color: white;

  .about-us-page__block-scroll {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    align-items: center;
    gap: 20px;

    @media screen and (max-width: 700px) {
      width: calc(100vw - 20px);
      box-sizing: border-box;
    }
  }

  .about-us-page__block {
    border-radius: 40px;
    padding: 30px;
    background-color: #faf5f1;
    height: 100%;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    gap: 32px;
    flex: 0 0 auto;

    @media screen and (max-width: 700px) {
      min-width: 280px; /* Устанавливаем минимальную ширину каждого дочернего блока */
    }

    .about-us-page__content-image {
      height: 200px;
      width: 100%;
      border-radius: 20px;

      img {
        height: 100%;
        width: 100%;
        border-radius: 20px;
        object-fit: cover;
      }
    }

    .about-us-page__content-block {
      display: flex;
      flex-direction: column;
      text-align: left;
      gap: 12px;

      p {
        margin: 0;
        font-family: "MontserratBold";
        font-size: 24px;
        line-height: 29px;

        @media screen and (max-width: 700px) {
          font-size: 20px;
        }
      }

      span {
        font-family: "MontserratLight";
        font-size: 18px;
        line-height: 27px;

        @media screen and (max-width: 700px) {
          font-size: 16px;
        }
      }
    }
  }
}
