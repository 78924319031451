.fixed-btn-record {
  position: fixed;
  padding: 10px;
  bottom: 0;
  height: auto;
  background: white;
  width: 100%;
  z-index: 100;
  box-sizing: border-box;

  .fixed-btn-record__button {
    border-radius: 12px !important;
    text-transform: uppercase;
    height: 3rem;
    width: 100%;
    font-size: 16px;
    line-height: 30px;
    letter-spacing: 2px;
    font-family: "MontserratBold";
    z-index: 1000;
    box-shadow: none;
  }
}
